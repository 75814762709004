.main {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2.4rem;
	color: #000000;
	margin-top: 2.4rem;
}

.meeting-point {
	color: #a81326;
	font-weight: 500;
}

@primary-color: #2196f3;