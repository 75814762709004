@import 'styles/variables';

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 31.7rem;
	background-image: url('../../../images/cover-photo.png');
	background-repeat: no-repeat;
	background-size: @app-width 31.7rem;
}

.circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 16rem;
	height: 16rem;
	background-image: url('../../../images/cover-photo-circle.svg');
	background-repeat: no-repeat;
	background-size: 16rem 16rem;
}

@primary-color: #2196f3;