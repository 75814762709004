@import 'styles/variables';

.img-bell {
	width: 4.485rem;
	height: 4.485rem;
}

.content-container {
	margin: 2.4rem 1.8rem 1.1rem 1.8rem;
}

.listbox-title {
	display: block;
	font-weight: 400;
	font-size: 1.5rem;
	line-height: 2.4rem;
	margin-bottom: 1.1rem;
}

.button {
	width: 100%;
	height: 4.2rem;
	margin-top: 2.4rem;
	margin-bottom: 6rem;
	border: none;
	background-color: @text-color;
	box-shadow: none;
	outline: none;

	&:hover {
		background-color: #000000;
		cursor: pointer;
	}

	&:disabled {
		background-color: #cccccc;
		cursor: default;
	}
}

.button-text {
	color: #ffffff;
	font-weight: 300;
	font-size: 1.5rem;
	line-height: 2.4rem;
}

.submit-spin&:global(.ant-spin) {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	& :global(.ant-spin-dot-item) {
		background-color: #000000;
	}
}

@primary-color: #2196f3;