@import 'styles/variables';

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(./fonts/sofiapro-regular.eot) format('eot');
	src: local('sofia-pro'), url(./fonts/sofiapro-regular.eot#iefix) format('embedded-opentype'),
		local('sofia-pro'), url(./fonts/sofiapro-regular.woff2) format('woff2'), local('sofia-pro'),
		url(./fonts/sofiapro-regular.woff) format('woff'), local('sofia-pro'),
		url(./fonts/sofiapro-regular.ttf) format('truetype'), local('sofia-pro'),
		url(./fonts/sofiapro-regular.svg) format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(./fonts/sofiapro-light.eot) format('eot');
	src: local('sofia-pro'), url(./fonts/sofiapro-light.eot#iefix) format('embedded-opentype'),
		local('sofia-pro'), url(./fonts/sofiapro-light.woff2) format('woff2'), local('sofia-pro'),
		url(./fonts/sofiapro-light.woff) format('woff'), local('sofia-pro'),
		url(./fonts/sofiapro-light.ttf) format('truetype'), local('sofia-pro'),
		url(./fonts/sofiapro-light.svg) format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'sofia-pro';
	src: local('sofia-pro'), url(./fonts/sofiapro-semibold.eot) format('eot');
	src: local('sofia-pro'), url(./fonts/sofiapro-semibold.eot#iefix) format('embedded-opentype'),
		local('sofia-pro'), url(./fonts/sofiapro-semibold.woff2) format('woff2'), local('sofia-pro'),
		url(./fonts/sofiapro-semibold.woff) format('woff'), local('sofia-pro'),
		url(./fonts/sofiapro-semibold.ttf) format('truetype'), local('sofia-pro'),
		url(./fonts/sofiapro-semibold.svg) format('svg');
	font-weight: 600;
	font-style: normal;
}

.FontSofiaProRegular {
	font-family: 'sofia-pro';
	font-weight: normal;
}
.FontSofiaProLight {
	font-family: 'sofia-pro';
	font-weight: 300;
}
.FontSofiaProSemiBold {
	font-family: 'sofia-pro';
	font-weight: 600;
}

* {
	box-sizing: border-box;
}

html {
	width: 100%;
	height: 100%;
	font-size: 10px;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: sofia-pro, Verdana, Geneva, sans-serif;
	font-weight: normal;
	min-width: 30.86rem;
}

span {
	color: @text-color;
	letter-spacing: 0.05rem;
}

#root {
	width: 100%;
	height: 100%;
}

.three-section-layout {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.section-one,
.section-three {
	flex: 0 1 auto;
}

.section-two {
	display: flex;
	flex: 1 1 auto;
	overflow: auto;
	height: 100%;
	width: 100%;
}
