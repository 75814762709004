.container {
	display: flex;
	align-items: center;
	margin: 0rem 1.8rem 0rem;
}

.text {
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 2.4rem;
	color: #000000;
}

@primary-color: #2196f3;