@import 'styles/variables';

.container {
	width: @app-width;
	padding: 2.5rem 7.7rem 4.2rem 1.8rem;
	display: flex;
	flex-direction: column;
}

.text {
	font-weight: 300;
	font-size: 1.3rem;
	line-height: 3.6rem;
	color: #666666;
	cursor: default;
}

.link-text {
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: #000000;
	}
}


@primary-color: #2196f3;