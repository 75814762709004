.container {
	display: flex;
	align-items: center;
	margin: 2.4rem 1.8rem 2.4rem;
}

.text {
	font-size: 2.4rem;
	font-weight: 600;
	line-height: 3rem;
}

@primary-color: #2196f3;