.cover-photo-text {
	font-weight: 600;
	font-size: 2.4rem;
	line-height: 3rem;
	color: #000000;
	text-align: center;
}

.content-container {
	height: 6rem;
	margin: 2.4rem 1.8rem 1.1rem 1.8rem;
}

@primary-color: #2196f3;