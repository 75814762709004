.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 6.6rem;
}

.logo {
	width: 12.6rem;
	height: 3.07rem;
}

@primary-color: #2196f3;