@import 'styles/variables';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 100%;
	min-height: 100%;
	background-color: #f5f5f5;
}

.content-container {
	min-width: @app-width;
	width: @app-width;
	background-color: #ffffff;
	height: auto;
}

@primary-color: #2196f3;